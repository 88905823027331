import React, { useState } from "react"
import logo from "../../images/logo/Black.png"
import selectIconDown from "../../images/sgnOverview/selectIconDown.svg"
import selectIconTop from "../../images/sgnOverview/selectIconTop.svg"
import { Link, navigate } from "gatsby"
import "../../style/navbar.scss"

import celerCBridgePng from "../../images/logo/celerCBridge.png"
import celerIm from "../../images/home/celerIm.svg"
import peti from "../../images/home/peti.svg"
import { isMobile } from "react-device-detect"

/* eslint-disable */

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default function NavBar() {
  const [productOpen, setProductOpen] = useState(false)
  const [buildOpen, setBuildOpen] = useState(false)
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [navToggle, setNavToggle] = useState("nav-bg")
  const [navLinkToggle, setNavLinkToggle] = useState("links links-collapse")
  const [menuToggle, setMenuToggle] = useState("")
  const productMenuList = [
    {
      text: "Celer IM",
      link: "https://im-docs.celer.network/developer/celer-im-overview",
      icon: celerIm,
    },
    {
      text: "cBridge",
      link: "https://cbridge.celer.network/",
      icon: celerCBridgePng,
    },
    {
      text: "Peti",
      link: "https://docs.peti.trade/overview/introduction",
      icon: peti,
    },
  ]
  const buildMenuList = [
    {
      text: "Celer IM SDK",
      link: "https://im-docs.celer.network/developer/celer-im-overview",
      icon: celerIm,
    },
    {
      text: "cBridge SDK",
      link: "https://cbridge-docs.celer.network/developer/cbridge-sdk",
      icon: celerCBridgePng,
    },
  ]
  const navbarHandler = () => {
    if (navbarOpen) {
      setNavbarOpen(false)
      setNavToggle("nav-bg")
      setNavLinkToggle("links links-collapse")
      setMenuToggle("")
    } else {
      setNavbarOpen(true)
      setNavToggle("nav-bg-active")
      setNavLinkToggle("links links-collapse-active")
      setMenuToggle("change")
    }
  }

  const dropDown = (title, menuList, setOpen, open, position = "") => {
    return (
      <div
        className="myDropDown"
        onMouseEnter={() => {
          setOpen(true)
        }}
        onMouseLeave={() => {
          setOpen(false)
        }}
      >
        <div className="myDropDownTitle">
          {title}
          {!isMobile && (
            <div className="myDropDownImg">
              {open ? (
                <img src={selectIconTop} alt="" />
              ) : (
                <img src={selectIconDown} alt="" />
              )}
            </div>
          )}
        </div>
        {(open || isMobile) && (
          <div
            className={"myDropDownMenu " + position}
            onMouseEnter={() => {
              setOpen(true)
            }}
          >
            <div className="myDropDownMenuBody">
              {menuList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="myDropDownMenuItem"
                    onClick={() => {
                      window.open(item.link, "_blank")
                      setProductOpen(false)
                    }}
                  >
                    <img src={item.icon} alt="" className="dropdownMenuIicon" />
                    <div className="textWidth">{item.text}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="nav-menu">
      <div className="container-fluid">
        <nav className="nav-container">
          <div className="brand">
            <Link to="/">
              <img src={logo} height="44px" alt="" />
            </Link>
          </div>

          <div className="toggler">
            <button onClick={() => navbarHandler()}>
              <span className={menuToggle}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </span>
            </button>
          </div>

          <div className={navLinkToggle}>
            <ul className="link-container align-items-center">
              <li>
                <span
                  onClick={() =>
                    navigate("/", {
                      replace: true,
                    })
                  }
                  className="link-router-text"
                >
                  HOME
                </span>
              </li>
              <li>
                {dropDown(
                  "PRODUCT",
                  productMenuList,
                  setProductOpen,
                  productOpen
                )}
              </li>
              <li>
                <span
                  onClick={() =>
                    navigate("/technology", {
                      replace: true,
                    })
                  }
                  className="link-router-text"
                >
                  TECHNOLOGY
                </span>
              </li>
              <li>
                {dropDown(
                  "BUILD",
                  buildMenuList,
                  setBuildOpen,
                  buildOpen,
                  "menuToLeft"
                )}
              </li>
              <li>
                <span
                  onClick={() =>
                    window.open("https://blog.celer.network/", "_blank")
                  }
                  className="link-router-text"
                >
                  BLOG
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    navigate("/community", {
                      replace: true,
                    })
                  }
                  className="link-router-text"
                >
                  COMMUNITY
                </span>
              </li>
              {/* <li>
                  <Link to="/about">about</Link>
                </li> */}
              <li>
                <span
                  onClick={() =>
                    navigate("/career", {
                      replace: true,
                    })
                  }
                  className="link-router-text"
                >
                  CAREER
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className={navToggle} />
    </div>
  )
}
