import React from "react"
import celerIMSDKPng from "../../images/logo/celerIMSDK.png"
import cBridgeSDKPng from "../../images/logo/cBridgeSDK.png"
import twitterLogo from "../../images/logo/twitter.svg"
import telegramLogo from "../../images/logo/telegram.svg"
import forumLogo from "../../images/logo/forum.svg"
import githubLogo from "../../images/logo/github.svg"
import discordLogo from "../../images/logo/discord.svg"
import celerCBridgePng from "../../images/logo/celerCBridge.png"
import celerIm from "../../images/home/celerIm.svg"
import peti from "../../images/home/peti.svg"
import { globalLinks } from "../../constants"

import UseIsMobile from "../../hooks/UseIsMobile"
import "./footer.scss"

const products = [
  {
    title: "Celer IM",
    icon: celerIm,
    link: globalLinks.productLinks.celerIm,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "cBridge",
    icon: celerCBridgePng,
    link: globalLinks.productLinks.cbridge,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "Peti",
    icon: peti,
    link: globalLinks.productLinks.peti,
    rel: "noopener noreferrer",
    target: "_blank",
  },
]

const build = [
  {
    title: "Celer IM SDK",
    icon: celerIMSDKPng,
    link: globalLinks.buildLinks.celerImSDK,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "cBridge SDK",
    icon: cBridgeSDKPng,
    link: globalLinks.buildLinks.cBridgeSDK,
    rel: "noopener noreferrer",
    target: "_blank",
  },
]

const explores = [
  {
    title: "Technology",
    link: "/technology#top",
  },
  {
    title: "Blog",
    link: globalLinks.exploreLinks.blog,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "Career",
    link: "/career#top",
  },
]
const bugBounties = [
  {
    title: "Bug Bounty - ImmuneFi",
    subTitle: "",
    link: globalLinks.bugBountyLinks.bugBountyImmunefi,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "Bug Bounty - SGN",
    subTitle: "(To Be Launched Soon!)",
    link: globalLinks.bugBountyLinks.bugBountySGN,
  },
]

const contactUs = [
  {
    title: "Product Support",
    link: globalLinks.contactUsLinks.productSupport,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "cBridge Partnership",
    link: globalLinks.contactUsLinks.cBridgePartnership,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "Celer IM Partnership",
    link: globalLinks.contactUsLinks.celerIMPartnership,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "Press Inquiries",
    link: globalLinks.contactUsLinks.pressInquiries,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    title: "Brand Assets",
    link: globalLinks.exploreLinks.brandAssets,
    rel: "noopener noreferrer",
    target: "_blank",
  },
]

const otherIcons = [
  {
    icon: twitterLogo,
    link: globalLinks.iconLinks.twitter,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    icon: telegramLogo,
    link: globalLinks.iconLinks.telegram,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    icon: forumLogo,
    link: globalLinks.iconLinks.forum,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    icon: githubLogo,
    link: globalLinks.iconLinks.github,
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    icon: discordLogo,
    link: globalLinks.iconLinks.discord,
    rel: "noopener noreferrer",
    target: "_blank",
  },
]

export default function Footer() {
  const [isMobile] = UseIsMobile()
  return (
    <>
      <div className="footer">
        <div className="footer-contents">
          {isMobile && <div className="footer-content-space"></div>}
          <div className="footer-content">
            <div className="footer-content-title">PRODUCTS</div>
            <div className="footer-content-subtitles">
              {products.map((item, index) => {
                return (
                  <a
                    key={index}
                    className="footer-content-subtitle footer-content-link"
                    href={item.link}
                    target={item.target}
                    rel={item.rel}
                  >
                    <div className="subtitle-icon">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="subtitle-text">{item.title}</div>
                  </a>
                )
              })}
              {!isMobile && (
                <div
                  className="footer-content-subtitle footer-content-link"
                  style={{ opacity: 0 }}
                >
                  <div className="subtitle-icon"></div>
                  <div className="subtitle-text">{"zhanwei"}</div>
                </div>
              )}
            </div>
            <div className="footer-content-space"></div>
            <div className="footer-content-title">BUILD</div>
            <div className="footer-content-subtitles">
              {build.map((item, index) => {
                return (
                  <a
                    key={index}
                    className="footer-content-subtitle footer-content-link"
                    href={item.link}
                    target={item.target}
                    rel={item.rel}
                  >
                    <div className="subtitle-icon">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="subtitle-text">{item.title}</div>
                  </a>
                )
              })}
            </div>
          </div>
          {isMobile && <div className="footer-content-space"></div>}
          <div className="footer-content">
            <div className="footer-content-title">Explore</div>
            <div className="footer-content-subtitles">
              {explores.map((item, index) => {
                return (
                  <a
                    key={index}
                    className="footer-content-subtitle footer-content-link"
                    href={item.link}
                    target={item.target}
                    rel={item.rel}
                  >
                    <div className="subtitle-text">{item.title}</div>
                  </a>
                )
              })}
              {!isMobile && (
                <div
                  className="footer-content-subtitle footer-content-link"
                  style={{ opacity: 0 }}
                >
                  <div className="subtitle-icon"></div>
                  <div className="subtitle-text">{"zhanwei"}</div>
                </div>
              )}
            </div>
            <div className="footer-content-space"></div>
            <div className="footer-content-title">Bug Bounty</div>
            <div className="footer-content-subtitles">
              {bugBounties.map((item, index) => {
                return (
                  <a
                    key={index}
                    className="footer-content-subtitle footer-content-link special"
                    href={item.link}
                    target={item.target}
                    rel={item.rel}
                  >
                    <div className="subtitle-text">{item.title}</div>
                    <div className="subtitle-text2">{item.subTitle}</div>
                  </a>
                )
              })}
            </div>
          </div>
          {isMobile && <div className="footer-content-space"></div>}
          <div className="footer-content" key="footer-products">
            <div className="footer-content-title">Contact Us</div>
            <div className="footer-content-subtitles">
              {contactUs.map((item, index) => {
                return (
                  <a
                    key={index}
                    className="footer-content-subtitle footer-content-link"
                    href={item.link}
                    target={item.target}
                    rel={item.rel}
                  >
                    <div className="subtitle-text">{item.title}</div>
                  </a>
                )
              })}
            </div>
          </div>
          {isMobile && <div className="footer-content-space"></div>}
          <div className="footer-content icons" key="footer-otherIcons">
            <div className="footer-content-title">Community</div>
            <div className="footer-content-subtitles other-icons">
              {otherIcons.map((item, index) => {
                return (
                  <a
                    key={index}
                    className="footer-content-link"
                    href={item.link}
                    target={item.target}
                    rel={item.rel}
                  >
                    <div className="icon">
                      <img src={item.icon} alt="" />
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-desc">
          <div className="footer-desc-block">
            <div>Contact: info@celer.network</div>
            <div className="footerLink">Privacy</div>
            <div className="footerLink">Terms</div>
          </div>
          <div className="footer-desc-block2">
            <div>
              © Copyright 2018-{new Date().getFullYear()} Celer Network.
            </div>
            <div>All Rights Reserved</div>
          </div>
        </div>
      </div>
    </>
  )
}
