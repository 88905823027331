const homeLinks = {
  intro: {
    learnMore: "https://www.celer.network/technology#top",
  },
  useCeler: {
    stateGuardianNetwork: {
      stakeCELR: "http://sgn.celer.network",
      runAValidator: "https://github.com/celer-network/sgn",
    },
    celerBridge: {
      learnMore:
        "https://blog.celer.network/2021/09/22/cbridge-2-0-coherent-blockchain-interoperability-powered-by-the-state-guardian-network/",
      listToken: "https://form.typeform.com/to/RWC0Ihge",
      useApp: "https://cbridge.celer.network/#/transfer",
    },
    layer2finance: {
      learnMore:
        "https://blog.celer.network/2021/04/02/eli5-layer2-finance-the-modern-subway-of-the-defi-city/",
      useApp: "https://zk.layer2.finance/",
    },
  },
  buildOnCeler: {
    cBridgeSDK: {
      sdkDoc: "https://cbridge-docs.celer.network/developer/cbridge-sdk",
      listYourToken: "https://form.typeform.com/to/RWC0Ihge",
    },
    celerInterChainMessageSDK: {
      sdkDoc: "https://im-docs.celer.network/developer/celer-im-overview",
      partnerOnboard: "https://form.typeform.com/to/LsetjLjC",
    },
  },
}

const technologyLinks = {
  overview: {
    celerIMDoc: "https://im-docs.celer.network/developer/end-to-end-flow",
  },
  valueCapture: {
    stakeCELR: "http://sgn.celer.network",
  },
}

const aboutLinks = {
  history: {
    sgn: "https://www.celer.network/celercore/",
  },
}

const globalLinks = {
  productLinks: {
    celerIm: "https://im-docs.celer.network/developer/celer-im-overview",
    peti: "https://docs.peti.trade/overview/introduction",
    cbridge: "https://cbridge.celer.network/#/transfer",
  },
  iconLinks: {
    telegram: "https://t.me/celernetwork",
    telegramChinese: "https://t.me/celernetworkcn",
    twitter: "https://twitter.com/CelerNetwork",
    weixin: "https://mp.weixin.qq.com/s/xivSQRgw1pmQhTVkCW2NuA",
    github: "https://github.com/celer-network",
    discord: "https://discord.com/invite/Trhab5w",
    forum: "https://forum.celer.network/",
    youtube:
      "https://www.youtube.com/channel/UCv0Rtomj6pfbOtY9eSu1kLA/featured",
    coinmarketcap: "https://coinmarketcap.com/community/profile/CelerNetwork/",
  },
  buildLinks: {
    cBridgeSDK: "https://cbridge-docs.celer.network/developer/cbridge-sdk",
    celerImSDK: "https://im-docs.celer.network/developer/celer-im-overview",
  },
  exploreLinks: {
    blog: "https://blog.celer.network/",
    brandAssets:
      "https://drive.google.com/drive/folders/1tIgzFdLUbtMW0-Pb1uwkzDfhWBO0bF4l",
  },
  bugBountyLinks: {
    bugBountyImmunefi: "https://immunefi.com/bounty/celer/",
    bugBountySGN: "/bugBountySGN",
  },
  contactUsLinks: {
    productSupport: "https://discord.gg/d4BSHhBSAP",
    cBridgePartnership: "https://form.typeform.com/to/RWC0Ihge",
    celerIMPartnership: "https://form.typeform.com/to/LsetjLjC",
    pressInquiries: "https://form.typeform.com/to/BgIlaRaG",
  },
}

const supportChainsFetchUrl =
  "https://cbridge-prod2.celer.app/v1/getTransferConfigsForAll"

export {
  homeLinks,
  technologyLinks,
  aboutLinks,
  globalLinks,
  supportChainsFetchUrl,
}
