import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"

function UseIsMobile() {
  const [isMobileState, setIsMobileState] = useState(false)
  useEffect(() => {
    setIsMobileState(isMobile)
  }, [])
  return [isMobileState]
}

export default UseIsMobile
